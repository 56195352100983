html, body {
  height: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
button,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
main,
summary,
time,
mark,
audio,
video {
  font-family: Montserrat;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0px;
  color: #4a4c49;
  letter-spacing: 0px;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  color: #4a4c49;
}

body {
  background-color: #f9f9f9;
  margin: 0;
  padding: 0px;
  border: 0;
  top: 0;
}
/* HTML5 fonts */
h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 70px;
}
h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
}
h3 {
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
}
h4 {
  font-weight: 500;
  font-size: 22px;
  line-height: 3;
  margin-top: 20px;
}
h5 {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.7;
}
p {
  color: #4a4c49;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 500;
}
a {
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 24px;
  color: #595959;
}
button {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: medium;
}
.button.listicle {
  background-color: #AF231C;
  padding: 10px 20px;
  color: white;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid #AF231C;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  min-width: 180px;
}
.alternate-button.listicle {
  background-color: #f9f9f9;
  color: #AF231C;
  padding: 10px 20px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid #AF231C;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  min-width: 180px;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
 /* {
  line-height: 1;
} */
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}